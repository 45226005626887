<template>
    <div class="row g-3">
        <div class="col-12 col-lg-4 blogpost " v-for="(blog,index) in data" :key="index">

            <router-link to="/blog/platforma-srpski-istrazivaci">
              <figure>
                  <img class="img-fluid img--animated-soft w-100" :src="require(`@/assets/blog.png`)" alt="Platforma Srpski Istraživači" title="Platforma Srpski Istraživači" height="225" width="350">
              </figure>
            </router-link>

          <router-link to="/blog/platforma-srpski-istrazivaci">
            <h4 class="title_m title_blue my-3">
              Platforma Srpski Istraživači
            </h4>
          </router-link>

            <p class="text_xl">
              Srbiju svake godine napusti na hiljade talentovanih ljudi, među kojima je veliki broj onih koji se bave,
              ili će se baviti, naučno istraživačkim radom na vrhunskom nivou ...
            </p>
          <div class="text_bold text_m">
            <div class="row px-3">
              <div class="d-inline-block mr-3">
                <img class="rounded-circle img--animated-soft" :src="require(`@/assets/avatar.png`)" alt="Avatar" width="40" height="40">
              </div>
              <div class="d-inline-block">
                Serbian Researchers<br>
                Jun 28, 2021
              </div>
            </div>

          </div>
        </div>
    </div>
</template>


<script>
export default{
    name: "BlogCard",
    props: ['data']
}

</script>

